<template>
    <div class="main level-vertical-center">
        <div class="edition-heart">
            <div class="logo cross-center">
                <img src="@/assets/images/login/logo.png">
                <span>后台管理系统</span>
            </div>
            <div class="heart-box">
                <div class="llustration">
                    <img src="@/assets/images/login/Illustration.png">
                </div>
                <div class="login-box">
                    <h2>欢迎登录</h2>
                    <el-form :hide-required-asterisk="true" class="login-form" ref="accountref" :model="account"
                        :rules="rules">
                        <el-form-item prop="name">
                            <el-input prefix-icon="el-icon-user-solid" v-model="account.name" placeholder="请输入账号">
                            </el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input prefix-icon="el-icon-lock" type="password" v-model="account.password"
                                placeholder="请输入密码" show-password></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="main-between">
                        <el-checkbox label="记住密码" name="type" v-model="checked" >
                        </el-checkbox>
                        <!-- <div class="forget-pass">
                            忘记密码?
                        </div> -->
                    </div>

                    <div class="login-btn" @click="register">
                        登录
                    </div>

                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import rules from '@/utils/rules';
    import {
        getStorageFromKey,
    } from '@/utils/LocalStorage.js'
    export default {
        name: 'login',
        mounted() {
            const account = getStorageFromKey('account')
            const pass = getStorageFromKey('pass')
            if (pass&& account) {
                this.account.name = account
                this.account.password = pass
                this.checked = true
            }
        },
        data() {
            return {
                account: {
                    name: '',
                    password: ''
                },
                rules: rules,
                checked: false
            }
        },
        methods: {
            register() {
                this.$refs.accountref.validate((valid) => {
                    if (valid) {
                        this.$store.dispatch('login/login', {
                            account: this.account,
                            checked: this.checked
                        })
                    }
                })


            },
        },
    }
</script>

<style lang="scss" scoped>
    .main {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        background-image: linear-gradient(to right, #fff, #D5EAFF);
    }

    .edition-heart {
        width: 1650px;
        height: 750px;

        .logo {
            img {
                width: 45px;
                height: 45px;
            }

            span {
                margin-left: 10px;
                color: #333333;
                font-size: 32px;
                font-weight: 600;
            }
        }

        .heart-box {
            box-sizing: border-box;
            padding: 0 45px;
            margin-top: 20px;
            display: flex;
            align-items: center;

            .llustration {
                flex: 2;
                min-width: 680px;

                img {
                    width: 100%;
                    height: 100%;
                }
            }

            .login-box {
                box-sizing: border-box;
                flex: 1;
                min-width: 444px;
                margin-left: 150px;
                border: 3px solid #475cf5;
                border-radius: 16px;
                padding: 30px 50px;
                background-color: #ffffff;

                .login-form {
                    margin-top: 40px;
                }
            }
        }
    }

    .forget-pass {
        color: #4458FE;
        font-size: 14px;
        cursor: pointer;
    }

    .login-btn {
        width: 100%;
        padding: 15px 0;
        margin-top: 40px;
        color: #FFFFFF;
        background-image: linear-gradient(to right, #4E87FF, #4458FE);
        border: 6px;
        text-align: center;
        cursor: pointer;

    }

    @media screen and (max-width:1280px) {
        .edition-heart {
            width: 1500px;
            min-height: 600px;

            .heart-box {
                .login-box {
                    margin-left: 50px;
                }
            }
        }
    }
</style>